<template>
  <div class="home">
    <div class="wrapper">
      <header class="main-header">
        <a href="#" class="logo">
          <span class="logo-mini">
            <a href="#" class="mdi mdi-view-sequential" data-toggle="offcanvas" role="button" id="menu" ref="btn" style="color: #fff;">
              <span class="sr-only">Toggle navigation</span>
            </a>
          </span>
          <section class="logo-lg">
            <p style="margin: 10px 0 0 10px;font-size: 20px;">货柜管理系统
              <a href="#" class="mdi mdi-view-sequential" data-toggle="offcanvas" role="button" id="menu" ref="btn" style="color: #fff;">
                <span class="sr-only">Toggle navigation</span>
              </a>
            </p>
          </section>
        </a>
      </header>
      <aside class="main-sidebar">
        <section class="sidebar">
          <ul class="sidebar-menu">
            <!-- <li class="active">
              <a class="multitabs" href="main.html" data-content="false">
                <i class="my-menu mdi mdi-home"></i>
                <span> 首页</span>
              </a>
            </li> -->

            <li class="treeview" v-for="v in menuList" :key="v.id">
              <a href="#" class="main-item">
                <i class="my-menu mdi" :class="v.pictureUrl"></i>
                <span v-if="v.children.length">{{ v.menuName }}</span>
                <span v-else><a :href="v.menuCode + '.html'" class="multitabs" data-content="false">{{ v.menuName }}</a></span>
                <i class="mdi mdi-chevron-down pull-right" v-if="v.children.length" style="margin-top: 6px;"></i>
              </a>
              <ul class="treeview-menu" v-if="v.children.length">
                <li v-for="p in v.children" :key="p.id">
                  <a class="multitabs" :href="p.menuCode + '.html'" data-content="false" style="margin-left: 10px;">
                    <!-- <i class="mdi mdi-chevron-double-right"></i> -->
                    {{ p.menuName }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </aside>
      <div class="navbar-custom-menu" style="position: fixed; top: 0; right: 0; z-index: 999999;">
        <ul class="nav navbar-nav">
          <li class="dropdown user user-menu">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown" style="display: flex; justify-content: space-between;align-items: center;">
              <img src="/images/avatar5.png" class="user-image" alt="User Image" />
              <span class="hidden-xs">{{ account }}</span>
              <i class="mdi mdi-menu-down"></i>
            </a>
            <ul class="dropdown-menu">
              <li class="user-header">
                <img src="/images/avatar5.png" class="img-circle" alt="User Image" />
                <p style="color: #263238;">{{ tenant.name }}</p>
              </li>
              <li>
                <div style="padding-bottom: 20px;text-align: center;">
                  <span>余额</span>
                  <span style="margin: 0 10px;font-size: 17px;">{{ accountBalance }}</span>
                  <span style="margin-left: 10px;font-size: 14px;font-weight:bold;color:#0E9CFF;cursor:pointer;"
                      @click="onCashOutShow()" v-if="isSystemRole || isSystem">提现
                  </span>
                </div>
              </li>
              <li class="user-body">
                <div class="row">
                  <div class="col-xs-4 text-center">
                    <span>{{ account }}</span>
                  </div>
                  <div class="col-xs-4 text-center">
                    <span class="role-type">{{ isSystemRole ? "管理员" : roles }}</span>
                  </div>
                  <div class="col-xs-4 text-center">
                    <span>{{ name }}</span>
                  </div>
                </div>
              </li>
              <li class="user-footer">
                <div class="pull-left">
                  <a href="#" class="btn btn-default btn-flat" v-on:click="onRestShow()">修改密码</a>
                </div>
                <div class="pull-right">
                  <a href="#" class="btn btn-default btn-flat" v-on:click="goLogin()">退出登录</a>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="content-wrapper" id="iframe-content"></div>

      <footer class="main-footer">
        <div class="pull-right hidden-xs"></div>
        Copyright &copy; 2023-2024 <span>货柜管理系统</span>. All rights reserved.
      </footer>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="30%" :modal="false">
      <el-form :model="form" style="width: 80%; margin: 0 auto;" label-width="100px">
        <el-form-item label="旧密码" prop="oldPassword" :rules="[
          { required: true, message: '请输入旧密码', trigger: 'blur' },
        ]">
          <el-input v-model="form.oldPassword" autocomplete="off" show-password>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword" :rules="[
          { required: true, message: '请输入新密码', trigger: 'blur' },
        ]">
          <el-input v-model="form.newPassword" autocomplete="off" show-password>
          </el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="newPassword2" :rules="[
          { required: true, message: '请输入新密码', trigger: 'blur' },
        ]">
          <el-input v-model="form.newPassword2" autocomplete="off" show-password>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button size="small" type="primary" @click="onRestPassword"
          :disabled="!form.oldPassword || !form.newPassword || !form.newPassword2">确 定</el-button>
        <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="提现" :visible.sync="dialog" width="30%" :modal="false" style="padding-top: 20vh;">
      <el-form style="width:300px; margin:0 auto;">
        <el-form-item label="提现金额">
          <el-input-number v-model="tx" @change="handleChange" :precision="2" :min="0"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button size="small" type="primary" @click="onCashOut()">确 定</el-button>
        <el-button size="small" @click="dialog = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { common } from "../../public/common/import";
export default {
  name: "Home",
  data() {
    return {
      common: common,
      that: this,
      name: "",
      tenant: {},
      department: {},
      isSystemRole: 0,
      isSystem: 0,
      roles: "",
      form: {},
      dialogFormVisible: false,
      menuList: [],
      tenantType: 2, //1公司，2分公司
      account: '',
      accountBalance: 0,
      dialog:false,
      tx: 0.00,
      txMax:0,
    };
  },
  mounted: function () {
    // 默认路由组件是Home，在Home中判断token，跳转login
    if (
      !localStorage.sellerRefreshToken ||
      localStorage.sellerRefreshToken == "undefined"
    ) {
      this.$router.replace({ path: "/login" });
      return;
    }
    if (localStorage.menuList) {
      this.menuList = JSON.parse(localStorage.menuList);
    }
    let deleteArr = []
    this.menuList.forEach((item, index) => {
      if (item['menuName'] === '会员列表') {
        deleteArr.push(index)
      }
      if (item['menuName'] === '移动库存') {
        deleteArr.push(index)
      }
    })
    if (deleteArr.length !== 0) {
      deleteArr.forEach((item) => {
        this.menuList.splice(item, 1)
      })
    }
    this.getInfo();
    setTimeout(() => {
      // document.querySelector("#menu").click();
      this.$refs.btn.click();
    }, 1);
    this.geFindDeviceTypeEnumList();
  },
  methods: {
    handleChange:function(){
      this.$forceUpdate();
    },
    onCashOut:function(){
      console.log(this.tx);
      if (!this.tx) {
        this.$message({ message: "请输入正确的提现金额！", type: 'warning', offset: 30, customClass: 'customMessages' });
        return;
      }
      if (this.txMax < this.tx) {
        this.$message({ message: "提现金额不能大于余额！", type: 'warning', offset: 30, customClass: 'customMessages' });
        return;
      }
      let url = "/Api/Tenant/V1/tenant/TenantCashOut";
      let data = { accountBalance: this.tx };
      POSTAUTHOUR(url,data).then(res => {
        this.$message({ message: "提现成功！", type: 'success', offset: 30, customClass: 'customMessages' });
        this.dialog = false;
      })
    },
    onCashOutShow: function () {
      this.dialog = true;
      this.tx = "";
      this.txMax = this.accountBalance;
    },
    geFindDeviceTypeEnumList: function () {
      //获取用户信息
      let url = "/Api/device/V1/deviceEnum/FindDeviceTypeEnumList";
      POSTAUTHOUR(url, {}).then((res) => {
        ;
      });
    },
    getInfo: function () {
      //获取用户信息
      let url = "/Api/URPM/V1/User/Profile";
      POSTAUTHOUR(url, {}).then((res) => {
        this.name = res.name;
        this.tenant = res.tenant;
        // this.roles = res.roles.join(',');
        for (const item of res.roles) {
          this.roles += `${item.name},`;
        }
        this.isSystemRole = res.isSystemRole;
        this.account = res.account
        this.department = res.department;
        localStorage.setItem('userInfoAccount', res.name);
        localStorage.setItem('userInfoId', res.id);
        localStorage.isSystemRole = this.isSystemRole;
        this.accountBalance = localStorage.getItem('accountBalance') ?? 0;
        res?.roles.forEach(item => {
          if(item.isSystem) {
            localStorage.isSystem = 1;
            this.isSystem = 1;
          }
        })
        this.tenantType = res.tenant.tenantType;
        if (this.isSystemRole) {
          this.getMenuList();
        } else {
          this.getUserMenu();
        }
      });
    },
    getUserMenu: function () {
      this.menuList = [];
      //获取用户权限
      let funList = [];
      let url = "/Api/URPM/V1/User/Menu";
      POSTAUTHOUR(url, { platformType: 1 }).then((res) => {
        //设置功能菜单列表
        for (let item of res) {
          item.children = item.childMenus || [];
          for (const c of item.children) {
            for (const fun of c.menuPermissions) {
              let str = `${c.menuCode}${fun.code}`;
              funList.push(str);
            }
          }
        }
        this.menuList = res;
        let deleteArr = []
        this.menuList.forEach((item, index) => {
          if (item['menuName'] === '会员列表') {
            deleteArr.push(index)
          }
          if (item['menuName'] === '移动库存') {
            deleteArr.push(index)
          }
        })
        if (deleteArr.length !== 0) {
          deleteArr.forEach((item) => {
            this.menuList.splice(item, 1)
          })
        }
        for (let i = 0; i < this.menuList.length; i++) {
          if (
            this.menuList[i].menuName === "分公司管理" &&
            this.tenantType === 2
          ) {
            this.menuList.splice(i, 1);
            i--;
          }
        }
        localStorage.menuList = JSON.stringify(this.menuList);
        localStorage.funList = JSON.stringify(funList);
        console.log('this.menuList', this.menuList[0])
        let defaultTitle = '';
        let defaultUrl = '';
        if(this.menuList[0].children && this.menuList[0].children.length) {
          defaultTitle = this.menuList[0].children[0].menuName;
          defaultUrl = this.menuList[0].children[0].menuCode + '.html';
        } else {
          defaultTitle = this.menuList[0].menuName;
          defaultUrl = this.menuList[0].menuCode + '.html';
        }
        parent.$(parent.document).data("multitabs").create({
          iframe: true,
          title: defaultTitle,
          url: defaultUrl,
          type: 'main'
        }, true);
      });
    },
    getMenuList: function () {
      //获取所有菜单
      let funList = [];
      let funStr = "";
      let url = "/Api/URPM/V1/menu/list/all";
      POSTAUTHOUR(url, { applicationId: 1 }).then((res) => {
        this.menuList = [];
        for (let item of res) {
          if (this.tenantType == 2 && item.menuName == "分公司管理") {
            continue;
          }
          if (!item.fatherId) {
            item.children = [];
            this.menuList.push(item);
          }
        }
        let deleteArr = []
        this.menuList.forEach((item, index) => {
          if (item['menuName'] === '会员列表') {
            deleteArr.push(index)
          }
          if (item['menuName'] === '移动库存') {
            deleteArr.push(index)
          }
        })
        if (deleteArr.length !== 0) {
          deleteArr.forEach((item) => {
            this.menuList.splice(item, 1)
          })
        }
        for (const m of this.menuList) {
          for (const item of res) {
            if (m.id == item.fatherId) {
              m.children.push(item);
              for (const fun of item.menuPermissions) {
                funStr = `${item.menuCode}${fun.code}`;
                funList.push(funStr);
              }
            }
          }
        }

        localStorage.menuList = JSON.stringify(this.menuList);
        localStorage.funList = JSON.stringify(this.funList);
        console.log('this.menuList', this.menuList[0])
        console.log($.fn.multitabs)
        let defaultTitle = '';
        let defaultUrl = '';
        if(this.menuList[0].children && this.menuList[0].children.length) {
          defaultTitle = this.menuList[0].children[0].menuName;
          defaultUrl = this.menuList[0].children[0].menuCode + '.html';
        } else {
          defaultTitle = this.menuList[0].menuName;
          defaultUrl = this.menuList[0].menuCode + '.html';
        }
        parent.$(parent.document).data("multitabs").create({
          iframe: true,
          title: defaultTitle,
          url: defaultUrl,
          type: 'main'
        }, true);
      });
    },
    goLogin: function () {
      localStorage.sellerToken = undefined;
      localStorage.sellerRefreshToken = undefined;
      this.$router.replace({ path: "/login" });
    },
    goPage: function () {
      let url = encodeURI("/page.html#/ProductMZ");
      parent.$(parent.document).data("multitabs").create(
        {
          iframe: true,
          title: "添加云商品",
          url: url,
        },
        true
      );
    },
    onRestShow: function () {
      this.dialogFormVisible = true;
      this.form = { oldPassword: "", newPassword: "", newPassword2: "" };
    },
    onRestPassword: function () {
      if (!this.form.oldPassword) {
        this.$message({ message: "请输入旧密码！", type: 'warning', offset: 30, customClass: 'customMessage' });
        return false;
      }
      if (!this.form.newPassword) {
        this.$message({ message: "请输入新密码！", type: 'warning', offset: 30, customClass: 'customMessage' });
        return false;
      }
      if (this.form.newPassword !== this.form.newPassword2) {
        this.$message({ message: "新密码与确认密码不相同！", type: 'warning', offset: 30, customClass: 'customMessage' });
        return false;
      }

      let url = "/Api/URPM/V1/user/ModifyPassword";
      let data = {
        oldPassword: this.form.oldPassword,
        newPassword: this.form.newPassword,
      };
      POSTAUTHOUR(url, data, { getRes: true }).then((res) => {
        if(res.state === 2) {
          this.$message({ message: res.data.errorMessage, type: 'warning', offset: 30, customClass: 'customMessage' });
        } else {
          this.dialogFormVisible = false;
          this.$message({ message: '修改成功！', type: 'success', offset: 30, customClass: 'customMessage' });
          this.goLogin();
        }
      });
    },
  },
  components: {},
};
</script>
<style>
.mt-wrapper {
  height: calc(100vh - 45px) !important;
  /* height: 100%; */
}

.my-menu {
  font-size: 18px;
  position: relative;
  left: -3px;
  top: 3px;
}

.mdi-chevron-double-right {
  position: relative;
  top: 2px;
  left: -4px;
  margin-right: 4px;
}

.mdi-chevron-left {
  position: relative;
  top: 6px;
}

.mdi-chevron-double-right {
  position: relative;
  top: 2px;
  left: -4px;
  margin-right: 4px;
}

.mdi-chevron-left {
  position: relative;
  top: 6px;
}

.home .main-header{
  overflow: hidden;
}

.home .main-header .logo:hover {
  background: #1e282c;
}

.home .main-header .logo {
  padding: 0;
  background: #222d32;
}

.home .logo .logo-type {
  display: inline-block;
  height: 30px;
  margin: 0 10px;
  line-height: 30px;
  padding: 3px 5px;
  background: #3399FF;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

.sidebar-collapse .home .main-header .logo .logo-mini {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  padding: 3px 0;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
}

.skin-blue .sidebar-menu>li {
  box-sizing: border-box;
  padding: 0 8px;
}

.skin-blue .sidebar-menu>li.active,
.skin-blue .sidebar-menu>li:hover {
  box-sizing: border-box;
  padding: 0 8px;
  border-left: 3px solid transparent;
}

.skin-blue .sidebar-menu>li.active>a::before,
.skin-blue .sidebar-menu>li:hover>a::before {
  content: "";
  height: 100%;
  width: 3px;
  background: #3399FF;
  position: absolute;
  top: 0;
  left: -8px;
}

.skin-blue .sidebar-menu>li.active>a,
.skin-blue .sidebar-menu>li:hover>a {
  border: none;
  border-radius: 8px;
  background: rgba(51, 153, 255, .2);
}

.skin-blue .main-header .navbar {
  background: #fff;
  color: #000;
}

.skin-blue .main-header .navbar .sidebar-toggle {
  color: #000;
}

.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background: #3399FF;
}

.skin-blue .main-header .navbar .nav>li>a,
.skin-blue .main-header .navbar .nav>li:hover>a,
.skin-blue .main-header .navbar .nav .open>a,
.skin-blue .main-header .navbar .nav .open>a:hover,
.skin-blue .main-header .navbar .nav .open>a:focus {
  color: #000;
  background: #fff;
}

.skin-blue .main-header li.user-header {
  background: #3399FF;
}

.skin-blue .main-header .user-body .text-center {
  cursor: default;
}

.skin-blue .main-header .user-body .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skin-blue .main-header .user-body .role-type {
  display: inline-block;
  padding: 3px 10px;
  background: #3399FF;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}
.skin-blue .sidebar-menu > li .mdi-chevron-down{
  transition: all .2s linear;
}
.skin-blue .sidebar-menu > li.active .mdi-chevron-down{
  transform: rotate(180deg);
}
.user-menu a{
  color: #fff;
}

.navbar-nav>.user-menu>.dropdown-menu>.user-footer {
  background-color: #fff;
}

.nav .open > a,
.nav .open > a:hover{
  padding-bottom: 9px;
  background-color: transparent!important;
}
.customMessage{
  z-index: 9999999!important;
}
.main-item:hover a{
  color: #fff!important;
}
.nav > li > a:hover{
  color: #fff;
}
.customMessages{
  z-index: 9999999!important;
}
</style>
