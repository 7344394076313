<template>
  <div class="login">
    <div class="login-box-my">
      <div style="margin-bottom: 1vh;font-size: 3.203vh;text-align: center;">
        货柜管理系统
      </div>
      <div class="info-item">
        <div class="info-label">用户名</div>
        <el-input v-model.trim="username" placeholder="请输入用户名" clearable></el-input>
      </div>
      <div class="info-item">
        <div class="info-label">密码</div>
        <el-input v-model.trim="password" placeholder="请输入密码" clearable show-password>
        </el-input>
      </div>
      <div class="login-btn">
        <el-button type="primary" @click="onLogin()" :loading="isLogin" :disabled="confirmDisabled">
          {{ isLogin ? '正在登录中....' : '登录' }}
        </el-button>
      </div>
      <div style="text-align: right;margin-top:10px;">
        <a href="#" style="color: #9ea7b4; font-size: 1.481vh;" @click="lossPassword">忘记密码</a>
      </div>
    </div>
  </div>
</template>
<script>
import { common } from "../../public/common/import";

export default {
  data() {
    return {
      username: "",
      password: "",
      isLogin: false,
      rightStyle: {
        position: 'relative',
        height: '100%',
        'background-color': '#FFF',
        display: 'inline-block'
      }
    }
  },
  created() {
    window.addEventListener("resize", () => {
      this.$nextTick(() => {
        this.rightStyle['width'] = common.getWidth('left') + 60 + 'px'
        this.$forceUpdate()
      })
    });
  },
  computed: {
    confirmDisabled() {
      if(!this.username || !this.password) {
        return true;
      }
      return false;
    },
  },
  mounted: function () {
    window.addEventListener("keydown", this.enterKeyEvent)
    this.$nextTick(() => {
      this.rightStyle['width'] = common.getWidth('left') + 60 + 'px'
      this.$forceUpdate()
    })
  },
  methods: {
    enterKeyEvent(e) {
      if (e.keyCode === 13) {
        this.onLogin()
      }
    },
    onLogin: function () {
      // return
      let that = this
      that.isLogin = true
      if (!this.username) {
        this.$message.warning('登录账号不能为空');
        that.isLogin = false
        return false;
      }
      if (!this.password) {
        this.$message.warning('密码不能为空');
        that.isLogin = false
        return false;
      }

      let url = "/api/login";
      let data = {
        username: this.username,
        password: this.password,
        platform_type: 2,
        client_id: "clientid",
        grant_type: "password"
      };
      $.ajax({
        url: `${requestUrl}${url}`,
        data: data,
        type: "POST",
        success: (res) => {
          localStorage.sellerToken = res['access_token'];
          localStorage.sellerRefreshToken = res.refresh_token;
          window.location.replace("/")
        },
        complete: (XHR, TS) => {
          if (XHR.status !== 200) {
            this.$message.error(XHR.responseJSON.error_description);
          }
          that.isLogin = false
        },
        error(err) {
          // that.$message.error('用户名或密码错误')
        },
      })

    },
    lossPassword: function () {
      this.$message.warning('请联系管理员，由管理员修改密码!');
    },
  }
}
</script>
<style scoped>
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/images/login-bg.png) no-repeat center center;
  background-size: cover;
}

.login-box-my {
  width: 30%;
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 15px;
  font-size: 1.592vh;
  user-select: none;
}

.login-box-my .info-item {
  margin: 3.314vh 0;
}

.info-item .info-label {
  margin-bottom: 0.9259vh;
  font-size: 1.592vh;
}

.login-box-my .login-btn {
  padding-top: 1.714vh;
}

.login-box-my .login-btn button {
  width: 100%;
  padding: 1.851vh 0;
  font-size: 2vh;
  border-radius: 10px;
  background-color: rgb(51, 153, 255);
  color: #fff;
}
.login-box-my .login-btn button:hover{
  background-color: rgb(20, 130, 240);
}
::v-deep .login-box-my .el-button--primary.is-disabled,
::v-deep .login-box-my .el-button--primary.is-disabled:hover{
  background-color: rgba(51, 153, 255, .5);
}
</style>
