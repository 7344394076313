<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { json } from 'body-parser';
import {common} from "../public/common/import";
export default {
  data() {
    return {
      common:common,
      that:this,
      addressList:[],
      deviceTypeList:[]
    }
  },
  mounted:function(){

    console.log('00000025');
    if (!localStorage.sellerRefreshToken||localStorage.sellerRefreshToken=='undefined') {
      // this.goLogin();
      return;
    }
    // resetToken();
    this.getAddress();
    this.getDeviceTypeList();
  },
  methods:{
    getAddress:function(){
      $.ajax({
        url:"/common/pca-code.json",
        success:(res)=>{
          this.addressList = res;
          localStorage.addressList = JSON.stringify(res);
          // ;
        }
      })
    },
    getDeviceTypeList:function(){
      //设备类型枚举列表
      let url = "/Api/device/V1/deviceEnum/FindDeviceTypeEnumList";
      let data = {};
      POSTAUTHOUR(url,data).then(res=>{
        //  ;
         this.deviceTypeList = res;

      })
    },
    closeTab:function(){
      console.log("关闭标签");
      let $thisTabs = $('.mt-nav-bar .nav-tabs').find('a.active');
      let $navTab =  window.$multitabs._getNavTab($thisTabs);
      window.$multitabs.close($navTab);
    },
    goLogin:function(){
        console.log("去登录");
        localStorage.sellerToken = undefined;
        localStorage.sellerRefreshToken = undefined;
        this.$router.replace({path:"/login"});
        // window.location.replace("/login")
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog__wrapper{
  margin: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 999999!important;
}
/deep/ .el-dialog{
  width: 20%;
  border-radius: 15px;
}
</style>
